<template>
  <div v-loading="loading" :element-loading-text="'加载中'" class="pannel">
    <div class="pannel-con">
      <div class="top">
        <img class="item-image" src="@/assets/img/study/tati.png">
        <div class="top-right">
          <div>{{ title }}</div>
          <div class="flex">
            <el-progress :show-text="false" :stroke-width="12" :percentage="baifen" status="exception"
              color="#FF7B1C" />
            <div class="qusnum">
              <span class="have">已完成{{
                isSubmit
                  ? subjectList.length - detail.NosubjectName
                  : complete
              }}题</span>/共{{ subjectList.length }}题
            </div>
          </div>
        </div>
      </div>
      <div :class="IsPurchase ? '' : 'content-box'">
        <div class="block">
          <div class="shiti-block">
            <div>
              <div v-for="(item, index) of subList" ref="queCarBox" :key="index">
                <que-car v-if="ifNew" ref="queCar" :paper-id="paperId" :is-purchase="IsPurchase" :see-num="seeNum"
                  :start="start" :is-submited="isSubmit" :detail="item" :exam-type="examType" @task="getNum" />
                <!-- <div v-if="isSubmit" class="queBut" @click="queClick(item)">我要提问</div> -->
              </div>
            </div>
          </div>
          <!-- 左侧 -->
          <div class="shiti-function">
            <div class="timebox">
              <i class="iconfont icon-daojishi" />
              答题时间
              <span v-if="time" class="countdown">
                {{ time.hours > 9 ? time.hours : '0' + time.hours }}:{{
                  time.minutes > 9 ? time.minutes : '0' + time.minutes
                }}:{{ time.seconds > 9 ? time.seconds : '0' + time.seconds }}
              </span>
            </div>
            <!-- 答题卡 -->
            <div class="sheet numberPannel m-t-20">
              <div class="sheet-header">
                <div v-if="!isSubmit" class="title">
                  答题卡
                  <img class="icon" src="@/assets/img/Home/hu.png">
                </div>
                <div class="flex">
                  <span class="num"><i class="iconfont icon-dian" style="color: #38c25f" />
                    {{
                      isSubmit
                        ? '正确 ' + detail.ZQsubjectName
                        : '已答 ' + complete
                    }}
                  </span>
                  <span v-if="isSubmit" class="num"><i class="iconfont icon-dian" style="color: #ff5d51" />错误{{ ' ' }}
                    {{
                      detail.mistakeName
                        ? detail.mistakeName
                        : 0
                    }}
                  </span>
                  <span class="num"><i class="iconfont icon-dian" style="color: #666" />未答{{
                    ' '
                  }}
                    {{
                      isSubmit
                        ? detail.NosubjectName
                          ? detail.NosubjectName
                          : 0
                        : subjectList.length - complete
                    }}</span>
                </div>
              </div>
              <!-- 试题类型 -->
              <div class="sheet-block">
                <div v-for="(shi, shiIndex) of subjectList" :key="shiIndex" class="t-item">
                  <div v-if="isSubmit" class="t-item" :class="((shi.questionType == 1 || shi.questionType == 6) &&
                    shi.questionUserAnswer.length) ||
                    (shi.questionType != 1 &&
                      shi.questionType != 6 &&
                      shi.questionUserAnswer)
                    ? Number(shi.isCorrect) == 0
                      ? 't-error'
                      : 't-sucess'
                    : 't-no'
                    " @click="jumpShiTi(shiIndex + 1)">
                    {{ shiIndex + 1 }}
                  </div>
                  <!-- 未提交 -->
                  <div v-else class="t-item" :class="shi.questionType == 1 || shi.questionType == 6
                    ? shi.questionUserAnswer &&
                      shi.questionUserAnswer.length > 0
                      ? 't-on'
                      : ''
                    : shi.questionUserAnswer
                      ? 't-on'
                      : ''
                    " @click="jumpShiTi(shiIndex + 1)">
                    {{ shiIndex + 1 }}
                  </div>
                </div>
              </div>
              <!-- 交卷 -->
              <div v-if="!isSubmit" class="btnlist" @click="ShowAnswerChange">
                我已答完,立即交卷
              </div>
              <div v-if="isSubmit && examType != 3" class="btnlist" @click="answerAgen">
                重新答题
              </div>
            </div>
          </div>
        </div>
        <div v-if="!IsPurchase" class="quest-tips">试看模式</div>
        <div v-if="!IsPurchase" class="quest-seek">
          <div class="seek">
            <div class="text">
              <i class="iconfont icon-suotou" />试看结束，购买解锁全部试题
            </div>
            <div v-if="!isSubmit" class="btnlist" @click="goAllOrder">
              立即购买
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="centerDialogVisible" width="30%" center>
      <span>试卷尚未购买无法答题</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">返回上一页</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 答疑 -->
    <!-- 课程答疑 -->
    <answer-view v-if="answerShow" :type="2" :status="4" :course-id="paperId" :is-purchase="IsPurchase"
      :chapter-id="queTi.subjectId" :if-list="false" @close="close" />
  </div>
</template>

<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo, getToken } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
import QueCar from './queCar.vue'
const answerClient = new AnswerClient()
import { queType } from '@/api/emun'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { Know } from '@/api/know'
const know = new Know()
import answerView from '@/components/Know/course/answerView.vue'
import { checkStudyCode } from '@/api/home.js'
export default {
  components: {
    QueCar,
    answerView
    /* ExamFeedBack, ExamAnalysis */
  },
  data() {
    return {
      ifNew: true,
      IsPurchase: false, // 是否购买
      centerDialogVisible: false,
      detail: {},
      queType: queType,
      title: '',
      totalScore: 0, // 总分
      paperId: '', // 试卷ID
      roomId: '', // 考场id
      type: null /* 1 知识套餐，2 直播    */,
      courseId: null,
      showId: null,
      classId: null,
      examType: 3, // 1 每日一练, 2 章节练习 随堂练习, 3 虚拟考场,4 试卷包 试卷  5  课程模考
      userInfo: {},
      isSubmit: false, // 是否显示解析
      isSubmitList: false, // 是否获取解析列表
      isShow: false, // 是否显示成绩
      subjectList: [], // 题目列表//提交时用勇于显示题号
      subList: [], // 题目列表-提交时用
      /* 计时器 */
      start: true, // 是否计时
      timeEnd: '',
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      /* 做题数 */
      complete: 0,
      baifen: 0,
      /* 加载中 */
      loading: false,
      num: 0,
      canClick: true,
      /* 题型 */
      TiXing: [0, 0, 0, 0, 0, 0],
      points: 0, // 试卷分数
      /* 试看 */
      seeNum: null,
      /* 产品详情 */
      orderDetail: {},
      answerShow: false,
      queTi: null,
      cardCode: null,

      timer: null // 定时器
    }
  },

  async created() {
    this.userInfo = getInfo()

    if (!this.userInfo) {
      Vue.prototype.goLoginView(false)
      return
    }

    this.cardCode = this.$route.query.cardCode
    // this.checkStudyCode();
    this.courseId = this.$route.query.courseId
      ? this.$route.query.courseId
      : null
    this.showId = this.$route.query.showId
      ? Number(this.$route.query.showId)
      : null
    this.classId = this.$route.query.classId
      ? Number(this.$route.query.classId)
      : null

    this.isSubmit =
      String(this.$route.query.isSubmited) == 'true'
    this.paperId = this.$route.query.paperId
      ? String(this.$route.query.paperId)
      : null
    this.IsPurchase = this.$route.query.IsPurchase == 'true'

    this.roomId = this.$route.query.roomId
      ? String(this.$route.query.roomId)
      : null
    this.examType = this.$route.query.examType
      ? Number(this.$route.query.examType)
      : null
    this.type = this.$route.query.type ? Number(this.$route.query.type) : null
    this.title = this.$route.query.title
      ? String(this.$route.query.title)
      : null

    this.isSubmitList = this.isSubmit
    await this.getShitiList()

    // 滚动条监控  答题卡 滚动到顶部悬浮
    // if (this.IsPurchase) {
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        const top = $(window).scrollTop()
        if (top >= 400) {
          $('.shiti-function').addClass('fixed-card')
        } else {
          $('.shiti-function').removeClass('fixed-card')
        }
      })
    })
    // }
    if (this.type != 12) {
      if (this.classId) {
        await know.getkonDetail(this.classId, 1, this.classId).then((res) => {
          this.orderDetail = res
          if (!this.cardCode && !this.IsPurchase) {
            this.IsPurchase = res.IsPurchase
          }
        })
      } else {
        await know
          .getkonDetail(
            this.type == 1 ? this.classId : this.courseId,
            this.type,
            this.classId
          )
          .then((res) => {
            this.orderDetail = res
            if (!this.cardCode && !this.IsPurchase) {
              this.IsPurchase = res.IsPurchase
            }
          })
      }
    }
    this.$forceUpdate()
    if (!this.isSubmit && this.IsPurchase) {
      this.timeInterval()
    }
    this.ifNew = false
    this.$nextTick(() => {
      this.ifNew = true
    })
  },
  mounted() { },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    if (this.isSubmited) {
      window.removeEventListener('scroll', this.scrollToTop)
    }
    next()
  },
  methods: {
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode
        }
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true
            this.$store.commit('IS_PURCHASE_CHANGE', true)
          }
        })
      }
    },
    /* 判断是否购买 */
    getDetail() { },
    // 计时器
    timeInterval() {
      this.timeEnd = setInterval(() => {
        // 回掉函数开始计时了
        this.time.seconds++
        if (this.time.seconds == 60) {
          this.time.seconds = 0
          this.time.minutes++
        }
        if (this.time.minutes == 60) {
          this.time.minutes = 0
          this.time.hours++
        }
      }, 1000)
    },
    // 获取试卷列表
    async getShitiList() {
      this.loading = true
      if (!this.isSubmitList) {
        await answerClient
          .selectPaperSubjectList(
            this.paperId,
            this.userInfo ? this.userInfo.id : undefined,
            this.classId ? this.classId : undefined
          )
          .then((res) => {
            if (res.code == 0) {
              if (this.type == 12) {
                this.IsPurchase = res.data.status == 1
              }
              this.title = res.data.paper.name
              this.totalScore = res.data.paper.totalScore
              this.seeNum = res.data.paper.seeNum
              this.Splicing(res.data.data, true)
            }
          })
      } else {
        if (this.examType == 3) {
          await answerClient
            .goOverPcSubjectAnswer(this.paperId, this.roomId, this.examType)
            .then((res) => {
              if (res.code == 0) {
                this.detail = res.data
                this.totalScore = res.data.paper.totalScore
                this.points = res.data.examResult.points
                this.Splicing(res.data.arrayList, false)
              }
            })
        } else {
          await answerClient
            .getPcSubjectAnswer(this.paperId, this.roomId, this.examType)
            .then((res) => {
              if (res.code == 0) {
                this.detail = res.data
                this.totalScore = res.data.paper.totalScore
                this.points = res.data.examResult.points
                this.Splicing(res.data.arrayList, false)
              }
            })
        }
        this.getNum()
      }
      this.$forceUpdate()
      this.loading = false
    },
    /* 试题拼接 */
    Splicing(list, ans) {
      this.TiXing = [0, 0, 0, 0, 0, 0]

      /* ans  是否是提交显示的 */
      let shiti = []
      for (const item of list) {
        item.questionType = Number(item.questionType)
        if (
          item.questionType == 0 ||
          item.questionType == 1 ||
          item.questionType == 6
        ) {
          const data = JSON.parse(item.questionOption)
          const sub = []
          for (const i in data) {
            const val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65)
            }
            sub.push(val)
          }
          item.optionList = sub
          if (ans && (item.questionType == 1 || item.questionType == 6)) {
            item.questionUserAnswer = []
          }
          if (!ans && (item.questionType == 1 || item.questionType == 6)) {
            if (item.questionUserAnswer) {
              item.questionUserAnswer = item.questionUserAnswer.split(',')
            } else {
              item.questionUserAnswer = []
            }
          }
          shiti.push(item)
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: '对', num: 'A' },
            { val: '错', num: 'B' }
          ]
          shiti.push(item)
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            for (const chil of item.childSubject) {
              chil.questionType = Number(chil.questionType)
              if (
                chil.questionType == 0 ||
                chil.questionType == 1 ||
                chil.questionType == 6
              ) {
                const data = JSON.parse(chil.questionOption)
                const sub = []
                for (const i in data) {
                  const val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65)
                  }
                  sub.push(val)
                }
                chil.optionList = sub
                if (ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  chil.questionUserAnswer = []
                }
                if (
                  !ans &&
                  (chil.questionType == 1 || chil.questionType == 6)
                ) {
                  if (chil.questionUserAnswer) {
                    chil.questionUserAnswer = chil.questionUserAnswer.split(',')
                  } else {
                    chil.questionUserAnswer = []
                  }
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: '对', num: 'A' },
                  { val: '错', num: 'B' }
                ]
              }
            }
            shiti = shiti.concat(item.childSubject)
          }
        } else {
          shiti.push(item)
        }
      }
      this.subjectList = shiti
      this.subList = list // 提交时用  同数据源数据会一起变化
      // 添加 字段
      for (const i in this.subjectList) {
        const item = this.subjectList[i]
        item.indexVal = Number(i) + 1
        this.TiXing[item.questionType]++
      }
    },
    /* 答案填写 */
    isChoose(item, val) {
      if (item.questionType === 0 || item.questionType === 2) {
        if (this.subjectIndex < this.subjectList.length - 1) {
          this.subjectIndex += 1
        }
      }
    },

    // 开始or暂停
    StartOrEnd() {
      if (this.isSubmit) {
        return
      }
      if (this.start) {
        clearTimeout(this.timeEnd) // 清除
        this.start = false
      } else {
        this.timeInterval()
        this.start = true
      }
      this.$forceUpdate()
    },

    /* 计算做题数 */
    async getNum() {
      this.complete = 0
      for (const item of this.subjectList) {
        if (item.questionType == 1 || item.questionType == 6) {
          if (item.questionUserAnswer.length > 0) {
            await this.complete++
          }
        } else {
          if (item.questionUserAnswer) {
            await this.complete++
          }
        }
      }
      const data = (this.complete / Number(this.subjectList.length)) * 100
      this.baifen = Math.round(data)
      this.$forceUpdate()
    },
    // 提交
    async ShowAnswerChange() {
      if (!this.canClick) {
        return
      }
      this.canClick = false
      const that = this
      this.StartOrEnd()
      if (this.userInfo != null) {
        if (this.subjectList.length - this.complete > 0) {
          this.$confirm('检测到您有未答的题目，是否确认提交？', '提交', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              that.postEvery()
            })
            .catch(() => {
              this.StartOrEnd()
            })
        } else {
          this.$confirm('确认交卷吗？', '提交', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              that.postEvery()
            })
            .catch(() => {
              this.StartOrEnd()
            })
        }
      }
      this.canClick = true
    },
    /* 重新答题 */
    answerAgen() {
      window.location.reload()
    },
    // 提交答案
    async postEvery() {
      this.loading = true
      const times =
        (await (this.time.hours > 0 ? this.hours * 60 * 60 : 0)) +
        (this.time.minutes > 0 ? this.minutes * 60 : 0) +
        this.time.seconds
      const data = await {
        roomId: this.roomId,
        examType: this.examType,
        paperId: this.paperId,
        questionSubjectPaperVoList: this.subList,
        answerTimes: times || 0,
        courseId: this.type == 6 ? undefined : this.courseId,
        showId: this.showId
      }
      /* 其他  */
      await answerClient.addPcSubjectAnswer(data).then(async (res) => {
        if (res.code == 0) {
          this.StartOrEnd()
          clearTimeout(this.timeEnd) // 清除
          this.isSubmitList = true
          await this.getShitiList()
        } else {
          this.loading = false;
          return
        }
      })

      this.$nextTick(() => {
        this.isSubmit = true
        // 监听滚动事件
        window.addEventListener('scroll', this.scrollToTop)
        this.$forceUpdate()
      })
    },
    close() {
      this.answerShow = false
      this.$forceUpdate()
    },
    // 跳转试题
    jumpShiTi(id) {
      $('html, body').animate(
        {
          scrollTop: $('#' + id).offset().top
        },
        400
      )
    },
    // 立即购买
    goOrder() {
      const token = getToken()
      if (token) {
        orderClient
          .addOrders(this.paperId, this.userInfo.id, this.title, 12)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    goAllOrder() {
      const token = getToken()
      if (token) {
        if (this.classId) {
          orderClient
            .addOrders(
              this.classId,
              this.userInfo.id,
              this.orderDetail.title,
              1
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          let id = null
          if (this.type == 1) {
            id = this.classId
          } else if (this.type == 12) {
            id = this.paperId
          } else {
            id = this.courseId
          }
          orderClient
            .addOrders(
              id,
              this.userInfo.id,
              this.type == 12 ? this.title : this.orderDetail.title,
              this.type
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    queClick(item) {
      this.answerShow = true
      this.queTi = item
    },

    scrollToTop() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.isElementNotInViewport(this.$refs.queCarBox)
      }, 500)
    },
    isElementNotInViewport(el) {

      if (el) {
        el.forEach((item, index) => {
          const rect = el[index].getBoundingClientRect()
          if (
            !(
              rect.top >=
              (window.innerHeight || document.documentElement.clientHeight) ||
              rect.bottom <= 0
            )
          ) {
            if (!this.subList[index].isShow) {
              this.$refs.queCar[index].init()
              this.subList[index].isShow = true
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.pannel-con {
  width: 1220px;
  margin: 0px auto;

  .shijuan-block {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 20px 30px;
    margin-bottom: 20px;
    background-color: #fff;

    margin-top: 30px;

    .shijuan-type {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;

      i {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .shijuan-title {
      text-align: center;
      font-size: 22px;
      margin: 0;
      padding: 2px 0px 27px;
      border-bottom: 1px dashed #e0e3e9;

      .grade {
        position: absolute;
        left: 40px;
        top: 0px;
        width: 150px;
        height: 80px;
        font-size: 50px;
        font-family: qiantumakeshouxieti;
        font-weight: 400;
        line-height: 80px;
        color: #ff5104;
      }
    }

    .content {
      font-size: 16px;
      color: #666;
      line-height: 2;
      padding-left: 60px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .warning {
    background-color: #fff9e9;
    border: 1px dashed #ffeec4;
    text-align: center;
    line-height: 2.3;
    font-size: 16px;
    color: #ed7321;
  }

  .block {
    width: 1220px;
    margin: auto;
    overflow: hidden;

    .shiti-block {
      float: left;
      width: 880px;
      position: relative;
      background: #fff;
    }

    // <!-- 左侧 -->
    .shiti-function {
      width: 320px;
      float: right;
      min-height: 300px;

      /* 答题时间 */
      .timebox {
        width: 100%;
        height: 80px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 80px;
        padding-left: 31px;

        .iconfont {
          font-size: 18px;
          margin-right: 9px;
        }

        .countdown {
          color: #ff5d51;
        }

        .red {
          color: red !important;
        }
      }

      .sheet {
        width: 100%;
        background-color: #fff;
        padding: 0px 20px 20px;

        .sheet-header {
          width: 100%;
          line-height: 58px;
          height: 58px;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            flex-shrink: 0;

            .icon {
              position: absolute;
              left: 15px;
              top: 40px;
              width: 18px;
              height: 6px;
            }
          }

          .num {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 14px;

            .iconfont {
              font-size: 8px;
              margin-left: 12px;
              margin-right: 4px;
            }
          }
        }

        .sheet-block {
          padding: 15px 0px 15px 15px;
          max-height: 360px;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;

          .t-item {
            width: 40px;
            height: 30px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            margin: 6px;

            .t-item {
              border: 1px solid #eeeeee;
              line-height: 28px;
              margin: 0px;
            }

            &:hover {
              background-color: #ee491f;
              color: #f5deb3;
            }
          }

          .t-on {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }

          .t-error {
            border: 1px solid #ff5d51;
            background: #ff5d51;
            color: #ffffff;
          }

          .t-no {
            border: 1px solid #666;
            background: #666;
            color: #ffffff;
          }

          .t-sucess {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }
        }

        .btnlist {
          width: 200px;
          height: 40px;
          line-height: 40px;
          margin: 83px auto 20px;
        }
      }
    }

    /*  */
    .fixed-card {
      position: fixed;
      top: 0px;
      right: calc(50% - 610px);
    }
  }
}

.btnlist {
  background: #ff5d51;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

/* 暂停 */
.start-mask {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  color: #fff;
  font-size: 30px;
  background: rgba(0, 0, 0, 0.23);

  .text {
    text-align: center;
    color: #fff;
    width: 910px;
    position: fixed;
    bottom: 50vh;
    /* margin-top: 50px; */
    z-index: 10;
  }
}

/*  */
.back {
  width: 112px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #a8abbe;
  border-radius: 17px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  line-height: 32px;
  color: #333333;

  text-align: center;
  z-index: 10;
  margin: 16px 0px 16px 18px;
}

/* 标题 */
.top {
  width: 100%;
  padding: 20px;
  background: #fff;
  height: 120px;
  display: flex;
  margin-bottom: 20px;

  .item-image {
    width: 60px;
    height: 100%;
    margin-right: 12px;
    flex-shrink: 0;
  }

  .top-right {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .el-progress {
      width: 730px;
    }

    .qusnum {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      color: #999;
      font-weight: 400;
      line-height: 16px;
      margin-left: 20px;

      .have {
        color: #ff5d51;
      }
    }
  }
}

.content-box {
  position: relative;
  width: 100%;

  .block {
    padding-bottom: 100px !important;
  }

  .quest-tips {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 96px;
    height: 32px;
    background: #ff5e51;
    border-radius: 0px 8px 0px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
  }

  .quest-seek {
    position: absolute;
    z-index: 100;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(153, 153, 153, 0.2);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #cccccc;

    .seek {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 88px;
      background: #ffffff;
      border-radius: 0px 0px 8px 8px;
      opacity: 1;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 32px;
      margin-top: 12px;

      .text {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 18px;

        .iconfont {
          font-size: 18px;
          color: #666666;
          margin-right: 12px;
        }
      }

      .btnlist {
        margin: 0px;
        width: 170px;
        line-height: 48px;
        height: 48px;
      }
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.queBut {
  width: 104px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #ff5e51;
  margin: 10px 0px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ff5e51;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}
</style>
