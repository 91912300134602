import { render, staticRenderFns } from "./examDetailss.vue?vue&type=template&id=09b8ef33&scoped=true&"
import script from "./examDetailss.vue?vue&type=script&lang=js&"
export * from "./examDetailss.vue?vue&type=script&lang=js&"
import style0 from "./examDetailss.vue?vue&type=style&index=0&id=09b8ef33&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b8ef33",
  null
  
)

export default component.exports